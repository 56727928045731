import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RouterHandler from './router';
import reportWebVitals from './reportWebVitals';
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { ResourceCheck } from '@bamboobox/b2resource-check/dist'
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"

const resources = ["integration_setup"]

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider loginURL={window._env_.LOGIN_URL} authcheckURL={window._env_.AUTH_URL}>
      <MuiThemeProvider theme={UItheme}>
        <ResourceCheck component={<RouterHandler />} resources={resources} />
        {/* <RouterHandler /> */}
      </MuiThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
