import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, Navigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { toast, ToastContainer } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ArrowBackIosNew } from "@mui/icons-material";
import { Check } from "@material-ui/icons";
import OutlinedInput from '@mui/material/OutlinedInput';
import {  FormControl, Input} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: '1.3rem',
        width: 200,
        height: "10vh"
    },

    instructionFrame: {
        position: 'absolute',
        width: '20vw',
        height: "60vh",
        marginTop: "50px",
        right: '5%',
        top: '14%',

        /* other / stroke */

        border: '1px solid rgba(0, 0, 0, 0.23)',
        boxSizing: 'border-box',
        borderRadius: '8px',
    },
    headingFrame: {
        /* Frame 913 */
        /* Auto layout */

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',

        position: 'relative',
        // height: '2vh',
        left: '5%',
        top: '5%',

    },
    InfoOutlined: {
        color: "#21DA8C!important",
        "& .css-1ps6pg7-MuiPaper-root": {
            color: "#21DA8C!important"
        }
    },
    heading: {
        /* Instruction */

        position: 'relative',
        left: '2%',
        top: '5%',

        /* subtitle1 */

        fontFamily: 'Poppins',
        fontFtyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '175%',
        /* identical to box height, or 28px */

        letterspacing: '0.15px',

        /* text/primary */

        color: 'rgba(0, 0, 0, 0.87)',


        /* Inside auto layout */

        flex: 'none',
        order: '1',
        flexGrow: '0',
        margin: '0px 8px',
    },

    instructionText: {
        position: 'relative',
        fontFamily: "nunito sans",

        left: '5%',
        top: '7%',
        paddingRight: '10%',

        /* body1 */

        fontFamily: 'Public Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        /* or 21px */

        letterSpacing: '0.15px',

        /* text/primary */

        color: 'rgba(0, 0, 0, 0.87)',
    }
}));


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function HubspotMap() {
    const userDetails = useAuthState();
    const tenantId = userDetails.user.tenantId;
    const navigate = useNavigate();
    const [value, setValue] = React.useState(null);
    const [portalId, setPortalId] = React.useState("");
    let query = useQuery();
    const classes = useStyles();
    let [status, setStatus] = useState(query.get("status") || "");
    const [startDate, setStartDate] = useState(null);
    const [externalPopup, setExternalPopup] = useState(null);

    useEffect(() => {
        if (!externalPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            try {
                let currentUrl = externalPopup.location.href;
                if (!currentUrl) {
                    return;
                }
                currentUrl = currentUrl.replace('/#', '');
                currentUrl = new URL(currentUrl);
                // console.log("currentUrl", currentUrl)
                let login_success = new URLSearchParams(currentUrl.search).get("status");

                // console.log("code External Popup", status, login_success);
                // console.log(" external Popup currnentUrl", typeof (currentUrl), currentUrl);
                if (login_success) { // || String(currentUrl).includes("bamboobox.in")
                    // console.log("External Popup this is printing");

                    // console.log(`external Popup The popup URL has URL code param = ${login_success}`);
                    externalPopup.close();

                    //clear timer at the end

                    setExternalPopup(null);
                    console.log("external Popup closed");
                    setStatus(true);
                    timer && clearInterval(timer);

                }
            }
            catch (e) {
                console.log("external Popup", e);
                return;
            }
        }, 2000)
    },
        [externalPopup]
    );

    function handleAuth() {


        const SCOPES = "crm.lists.read crm.objects.contacts.read crm.objects.contacts.write crm.objects.companies.write crm.lists.write crm.objects.companies.read crm.objects.deals.read crm.objects.deals.write crm.objects.owners.read";
        const REDIRECT_URI = `${window._env_.HUBSPOT_CRM_GOLDENCOPY}/hubspot/redirect`;
        const width = 550;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const title = `WINDOW TITLE`;
        // Build the auth URL

        const url =
            'https://app.hubspot.com/oauth/authorize' +
            `?client_id=${encodeURIComponent(window._env_.HUBSPOT_CLIENT_IN_CRM)}` +
            `&scope=${encodeURIComponent(SCOPES)}` +
            `&redirect_uri=${REDIRECT_URI}`;

        // console.log("url", url);

        const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
        setExternalPopup(popup);


    }

    const BulkSyncHandler = async (event) => {
        // if (!startDate) {
        //   toast("Please provide a Start Date");
        //   return;
        // }
        const responseForAccounts = await fetch(`${window._env_.HUBSPOT_CRM_GOLDENCOPY}/hubspot/get-crm-data/${tenantId}/accounts`, {
            method: 'POST',
            credentials: 'include',
        });
        
        const responseForDeals = await fetch(`${window._env_.HUBSPOT_CRM_GOLDENCOPY}/hubspot/get-crm-data/${tenantId}/deals`, {
            method: 'POST',
            credentials: 'include',
        });
        
        const responseForCon = await fetch(`${window._env_.HUBSPOT_CRM_GOLDENCOPY}/hubspot/get-crm-data/${tenantId}/contacts`, {
            method: 'POST',
            credentials: 'include',
        });
       
        toast(`Records has been synced.`);
    }
    const handleUpdatedValue = (e) => {
        setPortalId(e.toString())
    }

    useEffect(() => {
        let code = query.get("code") || "";

        // console.log("Hubspot api called ", `${window._env_.HUBSPOT_CRM_GOLDENCOPY}/hubspot/auth/callback/?code=${code}&tenantId=${tenantId}`);
        if (code) {

          fetch(`${window._env_.HUBSPOT_CRM_GOLDENCOPY}/hubspot/auth/callback/?code=${code}&tenantId=${tenantId}&portalId=${portalId}`, {
              method: 'GET',
              credentials : 'include',
            //   headers: {
            //       "auth-token" : `${localStorage.getItem('b2bToken')}`
            //   }
          }).then(res => {
            console.log("res coming from line 32", res);
            if (res.status === 200) {
              navigate('/hubspotmap/?status=success');
            } else {
              navigate('/hubspotmap?status=failed');
            }
            
          }).catch(e => {
            console.log("An error has occured line 40",e);
            navigate('/hubspotmap?status=failed');
          })
        }
        
        // localStorage.setItem('b2_code_sent', 'success');
      // api should be called
      
    });

    return (
        <React.Fragment>
            <span style={{ display: "flex", padding: "0px 0px 0px 0px", marginTop: "19px", marginLeft: "32px" }}>
                <ArrowBackIosNew sx={{ color: "#37CAD3", cursor: "pointer" }} onClick={() => { navigate('/') }} />
                <Typography variant="Ag" component="h2" gutterBottom sx={{ fontStyle: "nunito sans", fontSize: "16px", fontWeight: "600" }} >Hubspot</Typography>
            </span>
            {/* <Typography variant="h6" gutterBottom component="div" style={{ padding: "1vh", marginLeft: "1.5vh" }}>{'All > Hubspot'}</Typography> */}

            <div>
                <Typography sx={{ display: "flex", marginTop: "32px", marginLeft: 3, }}>Portal Id<Typography sx={{ color: "red" }}>*</Typography></Typography>
                <div style={{ marginLeft: 32, marginTop: 16 }}>

                    <FormControl >
                        <OutlinedInput className={classes.input} size="small" placeholder="CRM Portal Id" onInput={(e) => { handleUpdatedValue(e) }} />
                        {/* <MyFormHelperText /> */}

                    </FormControl>
                </div>
            </div>
            {/* <Button onClick={handleAuth} style={{ marginLeft: "1.3rem" }} variant="contained" disabled={status !== "failed" && status}>Authenticate</Button> */}



            <Stack spacing={3} direction="row" style={{ margin: "0 auto", display: "block", marginTop: "3vh", marginLeft: "20vh" }}>
                {status !== "authenticated" ?
                    <Button style={{ background: "#21DA8C", width: "113px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} onClick={handleAuth} variant="contained" disabled={status !== "failed" && status} startIcon={<Check />}>
                        INTEGRATE
                    </Button> :
                    <Button style={{ background: "#21DA8C", width: "113px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} onClick={BulkSyncHandler} variant="outlined" disabled={!(status === "success" || status === "authenticated")}  >
                        Bulk Sync
                    </Button>
                }

                {/* <Button onClick={BulkSyncHandler} variant="outlined" disabled={!(status === "success" || status === "authenticated")}>Bulk Sync</Button> */}
                <ToastContainer />
            </Stack>

            <div className={classes.instructionFrame}>
                <div className={classes.headingFrame}>
                    <InfoOutlinedIcon color="primary" />
                    <p className={classes.heading}>
                        Instruction
                    </p>
                </div>
                <p className={classes.instructionText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed turpis velit. Praesent vel varius elit, a viverra ex. Mauris malesuada commodo felis nec vestibulum. Cras pulvinar, purus a maximus gravida, ipsum lectus aliquam ante, et laoreet nisi lorem vitae augue. Proin at justo quis leo malesuada dapibus. Nullam pellentesque magna ut consequat viverra. Sed semper velit et tempor sodales.
                </p>
            </div>

        </React.Fragment>
    );
}

export default HubspotMap;