import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@material-ui/core'
import { HashRouter as Router, Switch, Route, useLocation } from "react-router-dom";
//import {Button,Dialog,DialogContent,TextField} from '@material-ui/core' 
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Icon from '@mui/material/Icon'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState, useMemo } from 'react';
import { BookmarkBorderSharp } from '@material-ui/icons';
//var img='/home/gopijaiswal/Desktop/Bamboobox/integrationui/src/components/'
import DialogBox from './dialogBox';
import { Button, Dialog, DialogContent, TextField } from '@material-ui/core' //import Card from '@mui/material/Card';
import UpdateDialog from './updatedialog';
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import './card.css'


const logos = [
	require('../logos/zoho.png'),
	require('../logos/salesforce.png'),
	require('../logos/hubspot.png'),
	require('../logos/bombora.png'),
	require('../logos/leadsift.png'),
	require('../logos/marketo.png'),
	require('../logos/MailChimp.png'),
	require('../logos/linkedin_integration.png'),
	require('../logos/nextroll.png'),
	require('../logos/Outreach.png'),
	require('../logos/hubspotmap.png'),
	require('../logos/leadfeeder.png'),
	require('../logos/salesloft.png'),
	require('../logos/pardot.png'),
	require('../logos/GoogleApis.png'),
	require('../logos/ZohoCampaign.png'),
	require('../logos/ZohoMap.png')
]

const useStyles = makeStyles(theme => ({
	cardNormal: {
		marginLeft: '4%',
		marginBottom: '2%',
		marginTop:'8px',
		width: '278px',
		height: '145px',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px',
		textAlign: 'center',
	},
	cardOrange: {
		marginLeft: '4%',
		marginBottom: '2%',
		marginTop:'8px',
		width: '278px',
		height: '145px',
		border: '1px solid #FFB026',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px',
		textAlign: 'center',
	},
	cardRed: {
		marginLeft: '4%',
		marginBottom: '2%',
		marginTop:'8px',
		width: '278px',
		height: '145px',
		border: '1px solid #F55843',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px',
		textAlign: 'center',
	},
	

	circle: {
		color: '#4CAF50',
		marginLeft: '0%',
		// float: 'right',
		position: 'absolute',
		top: 0,
		right: '15px'
	},
	content: {
		borderRadius: '8px',
		width: '550px',
		height: '250px',

	},
	action: {
		justifyContent: 'center',

	},
	actionWithError: {
		justifyContent: 'center',

	},
	icon: {
		cursor: 'pointer'

	},
	// EditWithError:{
	// 	display:'none'
	// },
	// WithoutError:{
	// 	marginTop:"12%"
	// },
	// WithError:{
	// 	marginTop:"22%",
		
	// },
	normal:{
		textAlign:"center",
		fontSize:"14px",
		fontWeight:"700",
		fontFamily:"Nunito Sans",
	},
	veryHigh:{
		color:"#DD5B49",
		textAlign:"center",
		fontSize:"14px",
		fontWeight:"700",
		fontFamily:"Nunito Sans",
	},
	high:{
		color:"#FF9800",
		textAlign:"center",
		fontSize:"14px",
		fontWeight:"700",
		fontFamily:"Nunito Sans",
	},
}));



function CustomCard(props) {
	const classes = useStyles();
	const errorCheck=()=>{
		let error={}
		props.notifications?.map((obj)=>{
			console.log("condition",obj?.details?.tool.toUpperCase()===props?.title?.toUpperCase(),obj.details,props.title)
			if(obj?.resolved===false && obj?.type.toUpperCase()==='token-expiry'.toUpperCase() && obj?.subResourceName?.toUpperCase()==='Integration Setup'.toUpperCase()){
				if(obj?.details?.tool.toUpperCase()===props?.title?.toUpperCase()){
					error= {severity:obj.severity,message:obj.message}
					// console.log("error",error);
				}
			}
		})
		console.log("error",error,props.title);
		return error;
	}
	const [ispresent,setIspersent] = useState([])
	const [isLoading,setIsLoading] = useState(true);
	const [open, setOpen] = useState(false)
	const [isAuthorize, setIsAuthorize] = useState(false);
	var [updateOpen, setUpdateOpen] = useState(false)
	const [showButton, setShowButton] = useState(false);
	// const errorData=errorCheck();
	const [errorMessage,setErrorMessage]=useState({});
	console.log("error message",errorMessage,props.title);

	const userDetails = useAuthState();
	const tenantId = userDetails.user.tenantId;
	const intentProvider = props.postKey === 'Intent' ? props.title.toUpperCase(): null;
	const navigate = useNavigate();

	// console.log("props from Customcard", props)

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}

	let query = useQuery();
	let [status, setStatus] = useState(query.get("status") || "");

	// function handleClick()
	// {
	//     setShowButton(true);
	// }
	// function handleOpenMailchimp(){
	// 	navigate('/mailchimp')
	// }
	// function handleOpenLinkedIn(){
	// 	navigate('/linkedIn');
	// }
	function handleOpenDialog() {
		setOpen(true);
	}
	function handleOpen() {
		setUpdateOpen(true);
	}
	// useEffect(() => {
	// 	//api to check if tenant id is there are not
	// 	if(intentProvider){
	// 	fetch(`${window._env_.INTENT_BACKEND}/intent/test/tenant/integration/${tenantId}/${intentProvider}`)
	// 		.then(response => response.json())
	// 		.then(data => setIsAuthorize(data.success));
	// 	}
	// }, [intentProvider])

	

	useEffect(()=>{
		const errorData=errorCheck();
		console.log("error",errorData);
		// if(Object.keys(errorData).length>0){
		// 	setShowButton(true)
		// }
		setErrorMessage(errorData);
	},[props.notifications])

	async function fetchData() {
		const postData = { "tenantId": tenantId, "tool": "CDP", "keys": [props.postKey] }
		// console.log("postData from fetchData", postData, props);
		const TCRes = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(postData),
			credentials: 'include'
		})
		const TCResJSON = await TCRes.json()
		// console.log("Data OF", TCResJSON)
		if (TCResJSON[0]) {
			if(postData.keys === "Intent"){ //to get the values already present in leadfeeder config
				// console.log("value for TCResJSON", TCResJSON[0]?.configVal)
				props?.setIntentToolsIntegrated(TCResJSON[0]?.configVal)
			}
			if (TCResJSON[0]['configVal']&&TCResJSON[0]['configVal'].toLowerCase().includes(props.title.toLowerCase())) {
				// console.log(props.title, "Auth already done")
				setStatus("true")
			}

		}
	}

	useEffect(()=>{
		// console.log("intenttooslIntegrated", props.intenttoolsIntegrated)
		fetchData()
	},[props.intenttoolsIntegrated])

	// if (!status) {
	// 	fetchData()
	// }
	console.log("hrrllp ",props.title,props.ispresent,props.ispresent.some(e=>e.toLowerCase()===props.title?.toLowerCase()))
	if(props.ispresent&&props.title&&props.ispresent.some(e=>e.toLowerCase()===props.title?.toLowerCase())){
		return <></>;
	}
	return (
		<Card className={!Object.keys(errorMessage).length>0?classes.cardNormal:errorMessage?.severity.toUpperCase()==='VERY HIGH'?classes.cardRed:classes.cardOrange} sx={{ borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)' }}
			onMouseOver={() => setShowButton(true)}
			onMouseLeave={() => setShowButton(false)}
		// onMouseOut={()=>setShowButton(false)}
		>
			<div className={classes.main}>
				<CardActionArea sx={{marginTop:"8px !important"}} >
					{
						((isAuthorize || status) && (!Object.keys(errorMessage).length>0)) 
					? 
					<CheckCircleIcon className={classes.circle} />
					: 
					<div className={classes.circle}>
					{
						Object.keys(errorMessage).length>0 && <>
						{
							(errorMessage?.severity.toUpperCase()==='VERY HIGH')?<ErrorIcon style={{width:"1.5vw",color:"#DD5B49"}} />:<WarningRoundedIcon style={{width:"1.5vw",color:"#FF9800"}} />
						}
						</>  
					}
					</div>}
					<div >
						<img style={{ objectFit: "scale-down", height: '80px', maxWidth: '180px' }} src={logos[props.img]} alt="logo not loaded..." />
					</div>

				</CardActionArea>

				<CardActions className={!Object.keys(errorMessage).length>0?classes.action:classes.actionWithError}>
					{showButton && (isAuthorize || status) && (!Object.keys(errorMessage).length>0) &&
						<div 	className={!Object.keys(errorMessage).length>0?classes.WithoutError:classes.WithError}><EditIcon className={classes.icon} onClick={handleOpen} />Edit
							{/* <span><DeleteIcon className={classes.icon} onClick={handleOpen} />Delete</span> */}
						</div>
					}
					{(!showButton && !Object.keys(errorMessage).length>0)?
					<div>
					<Typography className={classes.normal} sx={{textAlign: "center",
fontFamily: "Nunito Sans",
fontSize: "1rem",
fontStyle: "normal",
fontWeight: "400",
lineHeight:"1.5",
letterSpacing: "0.1px"}}>{props.title}</Typography>
					</div>:
					<div>
					{(!showButton && Object.keys(errorMessage).length>0) && 
					<div style={{display:'flex',flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
					{
						errorMessage?.severity.toUpperCase()==='VERY HIGH'?
						<>
						<Typography className={classes.veryHigh} sx={{textAlign: "center",
fontFamily: "Nunito Sans",
fontSize: "1rem",
fontStyle: "normal",
fontWeight: "400",
lineHeight:"1.5",
letterSpacing: "0.1px"}} >{"Disconnected"}</Typography>
						</>
						:
						<>
						<Typography className={classes.high}  sx={{textAlign: "center",
fontFamily: "Nunito Sans",
fontSize: "1rem",
fontStyle: "normal",
fontWeight: "400",
lineHeight:"1.5",
letterSpacing: "0.1px"}} color="#FF9800" >{errorMessage.message || "Disconnects soon"}</Typography>
						</>
						
					}
					</div>
					}
					</div>
					}
					{
						(showButton && ((!isAuthorize && !status) || (Object.keys(errorMessage).length>0))) && 
						(
							(props.img == 0 || props.img == 1 || props.img == 2 || props.img == 3 || props.img == 4)
								?
								(
									<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
									<Button 
									
									onClick={() => 
										navigate("/label/" + props.title.toLowerCase())
									}
										size="small" variant="contained"
										style={{ backgroundColor: "#21DA8C",width:"255px" ,marginRight:"6px"}}>
										<Typography  sx={{color:"#ffffff",
fontFamily: "Nunito Sans",
fontSize: "15px",
fontStyle: "normal",
fontWeight: "800",
lineHeight:"26px",
letterSpacing: "0.46px"}}>{(Object.keys(errorMessage).length>0)?"RECONNECT":"CONNECT"}</Typography>
										
									</Button>
									</div>
									
								)
								:
								(
									<div style={{display:"flex",alignItems:"center",justifyContent:"center"}} >
									<Button 
									
									size="small" variant="contained" 
									onClick={() => {
										if(props.title === "Leadfeeder"){
											props?.setLeadFeederDialog(true)
										}
										else{
											navigate("/" + props.title.toLowerCase())
										}
									}	 
									}
										style={{ backgroundColor: "#21DA8C",width:"255px",marginRight:"6px" }}>
										
										<Typography  sx={{ color:"#ffffff",
fontFamily: "Nunito Sans",
fontSize: "15px",
fontStyle: "normal",
fontWeight: "800",
lineHeight:"26px",
letterSpacing: "0.46px"}}>{(Object.keys(errorMessage).length>0)?"RECONNECT":"CONNECT"}</Typography>
									</Button>
									</div>
									
								)
						)
					}
				</CardActions>
			</div>
			<UpdateDialog updateOpen={updateOpen} setUpdateOpen={setUpdateOpen} open={open} setOpen={setOpen}></UpdateDialog>
			<DialogBox open={open} setOpen={setOpen} isAuthorize={isAuthorize} setIsAuthorize={setIsAuthorize} title={props.title}></DialogBox>
		</Card>
	);
}

export default CustomCard;