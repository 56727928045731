
const getTenantConfig = async (tenantId, tool, tenantConfigKeys) => {
  try {
    const tenantConfigResponse = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', 
      body: JSON.stringify({
        tenantId,
        tool,
        keys: tenantConfigKeys,
      }),
    });
    const tenantConfigResponseJson = await tenantConfigResponse.json();
    const tenantConfigResponseJsonArray = {};
    for (const tenantConfig of tenantConfigResponseJson) {
      tenantConfigResponseJsonArray[tenantConfig.configKey] = tenantConfig.configVal;
    }
    const tenantConfigResponseArray = [];
    for (const key of tenantConfigKeys) {
      if (key in tenantConfigResponseJsonArray) {
        tenantConfigResponseArray.push({ configKey: key, configVal: tenantConfigResponseJsonArray[key] });
      }
    }
    return tenantConfigResponseArray;
  } catch (err) {
    console.error(`Error occurred in getTenantConfig for ${tenantId},${tool},${tenantConfigKeys}`, err);
    return [];
  }
};

const getTenantConfigJson = async (tenantId, tool, tenantConfigKeys) => {
  try {
    // console.log(`tool value is ${tool}`);
    // console.log(`tenantConfigKeys value is ${tenantConfigKeys}`);

    /* const tenantConfigResponse = await fetch(`${process.env.TENANTCONFIG_API}/tenant/get-tenant-config-json`,{ */
    const tenantConfigResponse = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config-json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        tenantId,
        tool,
        keys: tenantConfigKeys,
      }),
    });
    const tenantConfigResponseJson = await tenantConfigResponse.json();
    // console.log(`tenantConfgResponseJson ${JSON.stringify(tenantConfigResponseJson)}`);
    if (!tenantConfigResponseJson.success) {
      throw 'failed to fetch tenant config';
    }
    const tenantConfigResponseJsonArray = {};
    for (const tenantConfig of tenantConfigResponseJson.data.configs) {
      tenantConfigResponseJsonArray[tenantConfig.configKey] = tenantConfig.configVal;
    }
    const tenantConfigResponseArray = [];
    for (const key of tenantConfigKeys) {
      if (key in tenantConfigResponseJsonArray) {
        tenantConfigResponseArray.push({ configKey: key, configVal: tenantConfigResponseJsonArray[key] });
      }
    }
    return tenantConfigResponseArray;
  } catch (err) {
    console.error(`Error occurred in getTenantConfigJson for ${tenantId},${tool},${tenantConfigKeys}`, err);
    return [];
  }
};

const setTenantConfig = async (tenantId, tool, tenantConfigKeysValues) => {
  try {
    const tenantConfigResponse = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/set-tenant-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        tenantId,
        tool,
        configArray: tenantConfigKeysValues,
      }),
    });
    const tenantConfigResponseJson = await tenantConfigResponse.json();
    return tenantConfigResponseJson.success;
  } catch (err) {
    console.error(`Error occurred in setTenantConfig for ${tenantId},${tool},${tenantConfigKeysValues}`, err);
    return false;
  }
};


export  {getTenantConfigJson,getTenantConfig,setTenantConfig,}

