import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '1.3rem',
    width: 200,
    height: "10vh"
  },

  instructionFrame: {
    position: 'absolute',
    width: '20vw',
    height: "60vh",
    marginTop: "50px",
    right: '5%',
    top: '14%',

    /* other / stroke */

    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  headingFrame: {
    /* Frame 913 */
    /* Auto layout */

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',

    position: 'relative',
    // height: '2vh',
    left: '5%',
    top: '5%',

  },
  InfoOutlined: {
    color: "#21DA8C!important",
    "& .css-1ps6pg7-MuiPaper-root": {
      color: "#21DA8C!important"
    }
  },
  heading: {
    /* Instruction */

    position: 'relative',
    left: '2%',
    top: '5%',

    /* subtitle1 */

    fontFamily: 'Nunito Sans !important',
    fontFtyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '175%',
    /* identical to box height, or 28px */

    letterspacing: '0.15px',

    /* text/primary */

    color: 'rgba(0, 0, 0, 0.87)',


    /* Inside auto layout */

    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: '0px 8px',
  },

  instructionText: {
    position: 'relative',
    fontFamily: "Nunito sans",

    left: '5%',
    top: '7%',
    paddingRight: '10%',

    /* body1 */

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    /* or 21px */

    letterSpacing: '0.15px',

    /* text/primary */

    color: 'rgba(0, 0, 0, 0.87)',
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function GoogleAds() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();


  let query = useQuery();
  let [status, setStatus] = useState(query.get("status") || "");
  let [bulkStatus, setBulkStatus] = useState("");
  const [externalPopup, setExternalPopup] = useState(null);

  async function fetchBulkStatus() {
    let postData = { "tenantId": tenantId, "tool": "GOOGLEADS", "keys": ["bulk_status"] }
    const getBulkStatus = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      credentials: 'include'
    })
    const ResJSON = await getBulkStatus.json()
    // console.log(ResJSON)
    if (ResJSON[0]) {
      if (ResJSON[0]['configVal'] === "SUCCESS") {
        console.log("Bulk sync already done")
        setBulkStatus("SUCCESS")
      }

    }
  }
  if (!bulkStatus) {
    fetchBulkStatus()
  }

  async function fetchData() {
    const postData = { "tenantId": tenantId, "tool": "CDP", "keys": ["MAP"] }
    const TCRes = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      credentials: 'include'
    })
    const TCResJSON = await TCRes.json()
    // console.log("Data", TCResJSON)
    if (TCResJSON[0]) {
      if (TCResJSON[0]['configVal'].includes("Googleads")) {
        console.log("GoogleAds Auth already done")
        setStatus("authenticated")
      }

    }
  }
  if (!status) {
    fetchData()
  }


  const connectClick = (e) => {
    const width = 550;
    const height = 600;
    // const location = "no";
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `WINDOW TITLE`;
    const url = `${window._env_.GOOGLE_ADS}/googleads-auth/get-url?tenantId=${tenantId}`

    const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
  }

  function handleAuth() {
    const title = `WINDOW TITLE`;
    const width = 550;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const url = `${window._env_.GOOGLE_ADS}/googleads-auth/get-url?tenantId=${tenantId}`;

    const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
  }

  toast.configure({
    autoClose: 4000,
    draggable: true,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
  });

  useEffect(() => {
    if (!externalPopup) {
      return;
    }
    console.log("code coming inside useEffect 215")
    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        let currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        currentUrl = currentUrl.replace('/#', '');
        currentUrl = new URL(currentUrl);
        const login_success = new URLSearchParams(currentUrl.search).get("status");
        if (login_success.toString() === 'success') {
          externalPopup.close();
          toast.success("GoogleApis Successfully Authenticated")
          setExternalPopup(null);
          setStatus(login_success);
          timer && clearInterval(timer);
        }
      }
      catch (e) {
        console.log("external Popup", e);
        // toast.error("GoogleApis Authentication Failed, please try again")
        return;
      }
    }, 2000)
  },
    [externalPopup]
  );

  return (
    <React.Fragment>

      <span style={{ display: "flex", padding: "0px 0px 0px 0px", marginTop: "19px", marginLeft: "32px" }}>
        <ArrowBackIosNew sx={{ color: "#37CAD3", cursor: "pointer" }} onClick={() => { navigate('/') }} />
        <Typography variant="Ag" component="h2" gutterBottom sx={{ fontStyle: "nunito sans", fontSize: "16px", fontWeight: "600" }} >GoogleAds</Typography>
      </span>

      <Stack spacing={3} direction="row" style={{ marginLeft: "250px", marginTop: "2500px", display: "block", marginTop: "10vh" }}>
        {!(status == "success" || status == "authenticated") ?
          <Button style={{ background: "#21DA8C", width: "113px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "Nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} variant="contained" disabled={status !== "failed" && status} onClick={connectClick} startIcon={<Check />}>
            INTEGRATE
          </Button>
          :

          <Button style={{ background: "#21DA8C", width: "113px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "Nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} variant="contained" disabled={bulkStatus == "SUCCESS" || !(status === "success" || status === "authenticated")} /* onClick={() => handleAuth() } */ >
            Bulk Sync
          </Button>

        }
        {/* <Button className={classes.Button} onClick={connectClick} variant="contained" disabled={status !== "failed" && status}>AUTHENTICATE</Button> */}
      </Stack>
      <div className={classes.instructionFrame}>
        <div className={classes.headingFrame}>
          <InfoOutlinedIcon color="primary" />
          <p className={classes.heading}>
            Instruction
          </p>
        </div>
        <p className={classes.instructionText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed turpis velit. Praesent vel varius elit, a viverra ex. Mauris malesuada commodo felis nec vestibulum. Cras pulvinar, purus a maximus gravida, ipsum lectus aliquam ante, et laoreet nisi lorem vitae augue. Proin at justo quis leo malesuada dapibus. Nullam pellentesque magna ut consequat viverra. Sed semper velit et tempor sodales.
        </p>
      </div>


    </React.Fragment>
  );
}

export default GoogleAds;