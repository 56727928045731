import React,{useEffect, useState} from "react";
import {useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ZohoMap() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
    
    let query = useQuery();
    useEffect(() => {
       
        let code = query.get("code") || "";
        const location = query.get("location") || "";
        console.log('code', code, 'and location', location);
        if (code) {
          console.log(`${window._env_.ZOHO_MAP}/zoho/auth/callback/?code=${code}&tenantId=${tenantId}`)
          fetch(`${window._env_.ZOHO_MAP}/zoho/auth/callback/?code=${code}&tenantId=${tenantId}&location=${location}`, {
              method: 'GET',
              credentials : 'include',
          }).then(res => {
            console.log('After api execution', res)
            if (res.status === 200) {
              navigate('/zohomap/?status=success');
            } else {
              navigate('/zohomap?status=failed');
            }
            
          }).catch(e=>{
            console.log('res error', e)
            navigate('/zohomap?status=error');
          })
        }
        

    });
    return (
      <React.Fragment>
          {/* {isLoading?
          
          :<div style={{textAlign: "center", paddingTop: "45vh"}}><p>LinkedIn is authenticated. You can close this tab</p><p>- Team Bamboobox</p></div>} */}
<div style={{ position: "absolute", top: "50%", left: "50%", translate: "transform(-50%,-50%)" }}><CircularProgress color="#1f454f" /></div>
      </React.Fragment>
    );
  }

  export default ZohoMap;