import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuthState } from "@bamboobox/b2logincheck";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { Check } from "@material-ui/icons";
import {
  getTenantConfig,
  setTenantConfig,
} from "../../utilities/fetchTenantConfig";
import DomainMultiSelect from "./DomainMultiSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: "1.3rem",
    width: 200,
    height: "10vh",
  },

  instructionFrame: {
    position: "absolute",
    width: "20vw",
    height: "60vh",
    marginTop: "50px",
    right: "5%",
    top: "14%",

    /* other / stroke */

    border: "1px solid rgba(0, 0, 0, 0.23)",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  headingFrame: {
    /* Frame 913 */
    /* Auto layout */

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",

    position: "relative",
    // height: '2vh',
    left: "5%",
    top: "5%",
  },
  InfoOutlined: {
    color: "#21DA8C!important",
    "& .css-1ps6pg7-MuiPaper-root": {
      color: "#21DA8C!important",
    },
  },
  heading: {
    /* Instruction */

    position: "relative",
    left: "2%",
    top: "5%",

    /* subtitle1 */

    fontFamily: "Nunito Sans !important",
    fontFtyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "175%",
    /* identical to box height, or 28px */

    letterspacing: "0.15px",

    /* text/primary */

    color: "rgba(0, 0, 0, 0.87)",

    /* Inside auto layout */

    flex: "none",
    order: "1",
    flexGrow: "0",
    margin: "0px 8px",
  },

  instructionText: {
    position: "relative",
    fontFamily: "Nunito sans",

    left: "5%",
    top: "7%",
    paddingRight: "10%",

    /* body1 */

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    /* or 21px */

    letterSpacing: "0.15px",

    /* text/primary */

    color: "rgba(0, 0, 0, 0.87)",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ZohoMap() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
  // const [value, setValue] = React.useState(null);
  const [value, setValue] = useState(null);

  const classes = useStyles();

  let query = useQuery();
  let [status, setStatus] = useState(query.get("status") || "");
  // const [startDate, setStartDate] = useState(null);

  let [bulkStatus] = useState("");
  const [externalPopup, setExternalPopup] = useState(null);
  // const [enableSync, setEnableSync] = useState(false);
  // const [hasAccessOfLeadGen, setHasAccesOfLeadgen] = useState(false);

  const setIntegratedCheckMark = async (tenantId) => {
    if (!status) {
      return;
    }

    const currentTenantConfig = await getTenantConfig(tenantId, "CDP", ["MAP"]);
    let updateorInsertTenantConfig;

    if (!currentTenantConfig.length) {
      updateorInsertTenantConfig = await setTenantConfig(tenantId, "CDP", [
        { tenantConfigKey: "MAP", tenantConfigValue: "ZohoMAP" },
      ]);
    } else {
      if (!currentTenantConfig[0].configVal.includes("ZohoMAP")) {
        const newConfigVal =
          currentTenantConfig[0].configVal + "," + "ZohoMAP";
        updateorInsertTenantConfig = await setTenantConfig(tenantId, "CDP", [
          { tenantConfigKey: "MAP", tenantConfigValue: newConfigVal },
        ]);
      }
    }
  };
  useEffect(() => {
    setIntegratedCheckMark(tenantId);
  }, [status]);

  async function fetchData() {
    const TCRes = await getTenantConfig(tenantId, "CDP", ["MAP"]);
    // console.log("Data", TCRes)
    if (TCRes[0]) {
      if (TCRes[0]["configVal"].includes("ZohoMAP")) {
        console.log("ZohoMAP Authentication has already been done");
        setStatus("authenticated");
      }
    }
  }
  if (!status) {
    fetchData();
  }

  const connectClick = (e) => {
    const width = 550;
    const height = 600;
    // const location = "no";
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `WINDOW TITLE`;
    // const scope = "ZohoMarketingAutomation.campaign.CREATE+ZohoMarketingAutomation.campaign.READ+,ZohoMarketingAutomation.campaign.UPDATE+,ZohoMarketingAutomation.lead.ALL";
    const url = `https://accounts.zoho.in/oauth/v2/auth?scope=${window._env_.ZOHO_MAP_SCOPE}&client_id=${window._env_.ZOHO_MAP_CLIENT_ID}
    &response_type=code&access_type=offline&prompt=consent&redirect_uri=${window._env_.ZOHO_MAP_CALLBACK}`;

    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };

  const handleAuth = async () => {
    try {
      const url = `${window._env_.ZOHO_MAP}/zoho-map-create/new/job?tenantId=${tenantId}`;
  
      const requestBody = [
        {
          "task": "CAMPAIGN_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGN_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_OPEN_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_OPEN_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_SENT_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_SENT_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_CLICK_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_CLICK_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_OPTOUT_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_OPTOUT_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_SENT_HARD_BOUNCE_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_SENT_SOFT_BOUNCE_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "CAMPAIGNS_RECEPIENTS_BOUNCE_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },

        {
          "task": "PROGRAM_MEMBERS_TO_GC_BULK",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "PROGRAM_MEMBERS_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },


        {
          "task": "LIST_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "LEADS_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "LEADS_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "WEB_PAGE_VISIT_TO_CDP",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        },
        {
          "task": "TAGS_TO_GC",
          "lastSyncDate": "1997-01-02T11:40:39.823Z",
          "type": "user",
        }

      ];
  
      try {
        toast.success("Your data is being synced. This may take some time.", {
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
  
        for (const obj of requestBody) {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(obj),
          });
          console.log("response", response);
        }
  
        const selectedDomains = value || [];
  
        const saveDomainsUrl = `${window._env_.ZOHO_MAP}/zoho/save-domains`; 
        // const saveDomainsUrl = `http://localhost:3045/zoho/save-domains`; // 

        const saveDomainsResponse = await fetch(saveDomainsUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            tenantId,
            domains: selectedDomains,
          }),
        });
  
        if (saveDomainsResponse) {
          console.log('Selected domain names saved successfully:', selectedDomains);
        } else {
          console.error('Error saving selected domain names:', saveDomainsResponse.statusText);
        }
  
        console.log('All tasks completed successfully');
      } catch (error) {
        console.error('Error in POST request:', error);
      }
    } catch (error) {
      console.error('Error in handleAuth:', error);
    }
  };
  
  
  
 

  toast.configure({
    autoClose: 4000,
    draggable: true,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
  });

  useEffect(() => {
    if (!externalPopup) {
      return;
    }
    console.log("code coming inside useEffect 215");
    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        let currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        currentUrl = currentUrl.replace("/#", "");
        currentUrl = new URL(currentUrl);
        const login_success = new URLSearchParams(currentUrl.search).get(
          "status"
        );
        if (login_success.toString() === "success") {
          externalPopup.close();
          toast.success("ZohoMAP Successfully Authenticated");
          setExternalPopup(null);
          setStatus(login_success);
          timer && clearInterval(timer);
        }
      } catch (e) {
        console.log("external Popup", e);
        return;
      }
    }, 2000);
  }, [externalPopup]);

  return (
    <React.Fragment>
      <span
        style={{
          display: "flex",
          padding: "0px 0px 0px 0px",
          marginTop: "19px",
          marginLeft: "32px",
        }}
      >
        <ArrowBackIosNew
          sx={{ color: "#37CAD3", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <Typography
          variant="Ag"
          component="h2"
          gutterBottom
          sx={{ fontStyle: "nunito sans", fontSize: "16px", fontWeight: "600" }}
        >
          Zoho MAP
        </Typography>
      </span>

      <Stack
        spacing={3}
        direction="row"
        style={{
          marginLeft: "250px",
          marginTop: "2500px",
          display: "block",
          marginTop: "10vh",
        }}
      >
        {!(
          status === "success" ||
          status === "authenticated"
        ) ? (
          <Button
            style={{
              background: "#21DA8C",
              width: "113px",
              borderRadius: "18px",
              color: "#FFFFFF",
              fontWeight: 700,
              fontFamily: "Nunito sans",
              fontStyle: "bold",
              fontSize: "12px",
              height: "32px",
              marginLeft: "11px",
            }}
            variant="contained"
            disabled={status !== "failed" && status}
            onClick={connectClick}
            startIcon={<Check />}
          >
            INTEGRATE
          </Button>
        ) : (
          <>
            <DomainMultiSelect tenantId={tenantId} setValue={setValue} />
            <Button
              style={{
                background: "#21DA8C",
                width: "113px",
                borderRadius: "18px",
                color: "#FFFFFF",
                fontWeight: 700,
                fontFamily: "Nunito sans",
                fontStyle: "bold",
                fontSize: "12px",
                height: "32px",
                marginLeft: "11px",
              }}
              variant="contained"
              disabled={
                bulkStatus === "SUCCESS" ||
                !(status === "success" || status === "authenticated")
              }
              onClick={handleAuth}
            >
              Bulk Sync
            </Button>
          </>

        )}
      </Stack>
      <div className={classes.instructionFrame}>
        <div className={classes.headingFrame}>
          <InfoOutlinedIcon color="primary" />
          <p className={classes.heading}>Instruction</p>
        </div>
        <p className={classes.instructionText}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed
          turpis velit. Praesent vel varius elit, a viverra ex. Mauris malesuada
          commodo felis nec vestibulum. Cras pulvinar, purus a maximus gravida,
          ipsum lectus aliquam ante, et laoreet nisi lorem vitae augue. Proin at
          justo quis leo malesuada dapibus. Nullam pellentesque magna ut
          consequat viverra. Sed semper velit et tempor sodales.
        </p>
      </div>
    </React.Fragment>
  );
}

export default ZohoMap;

