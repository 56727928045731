import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme=>({
    paper:{
        
        padding:'10px',
        border:'none'
    },
    container: {
       display: "flex",
       postion:"absolute",
       height: "100%",
       width: "100%"
     },
     panel: {
       width:"100%"
     },
     tool:{
       //   marginTop:'10px',
         paddingLeft:'20px',
         paddingTop:'10px',
         color:"rgba(0, 0, 0, 0.87)",
         fontSize:"16px",
         fontWeight:"600",
     },
     tab:{
       textTransform: 'capitalize', 
       fontFamily:'Nunito Sans !important',
       fontStyle: 'normal',
       fontWeight: 'bold',
       
       fontSize: '17px'
     }
   
}));

export {useStyles}