import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";
import { toast, ToastContainer } from 'react-toastify';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LinkedIn() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false)

  let query = useQuery();
  useEffect(() => {

    let code = query.get("code") || "";
    // console.log("LinkedIn api called ", `${window._env_.LINKEDIN_GOLDENCOPY}/linkedin/auth/callback/?code=${code}&tenantId=${tenantId}`);
    console.log(code);
    if (code !== "") {

      fetch(`${window._env_.LINKEDIN_GOLDENCOPY}/linkedin/auth/callback/?code=${code}&tenantId=${tenantId}`, {
        method: 'GET',
        credentials: 'include',
        //   headers: {
        //       "auth-token" : `${localStorage.getItem('b2bToken')}`
        //   }
      }).then(res => {
        if (res.status === 200) {
          navigate('/linkedIn/?status=success');
        } else {
          navigate('/linkedIn?status=failed');
        }

      }).catch(e => {
        navigate('/linkedIn?status=failed');
      })
    }
    else {
      navigate('/linkedIn?status=failed');
    }


    // localStorage.setItem('b2_code_sent', 'success');
    // api should be called

  });

  return (
    <>
      <React.Fragment>
        {/* {isLoading?
        
        :<div style={{textAlign: "center", paddingTop: "45vh"}}><p>LinkedIn is authenticated. You can close this tab</p><p>- Team Bamboobox</p></div>} */}
        <div style={{ position: "absolute", top: "50%", left: "50%", translate: "transform(-50%,-50%)" }}><CircularProgress color="#1f454f" /></div>
      </React.Fragment>
    </>


  );
}

export default LinkedIn;