import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox } from "@mui/material";
import TextField from "@material-ui/core/TextField";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { withStyles } from "@mui/styles";
import "./DomainMultiSelect.css";

const CustomColorCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#E842EC",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DomainMultiSelect = ({tenantId,setValue}) => {
  const [domainNames, setDomainNames] = useState([]);

  useEffect(() => {
    fetchDomainNames();
  }, []);

  const fetchDomainNames = async () => {
    try {
      const response = await fetch(
        `${window._env_.ZOHO_MAP}/zoho-map/getdomains?tenantId=${tenantId}`,
        // `http://localhost:3045/zoho-map/getdomains?tenantId=a0ecf2ac-353e-5b29-934d-dcefea9c0674`,

        {
          method: 'GET',
          credentials: 'include',
        }
      );
      const result = await response.json();
      

      setDomainNames(result.data);
    } catch (error) {
      console.error('Error fetching domain names:', error);
    }
  };

  return (
    <>
      <div style={{ paddingLeft: "4vw", paddingTop: "2vw", borderRadius: "0px" }}>
        <Typography style={{ fontWeight: 550, paddingLeft: "3px" }}>
          Sync Based on Domains
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Autocomplete
            multiple
            id="domain-auto"
            style={{ width: "576px", marginBottom: "15px" }}
            popupIcon={
              <KeyboardArrowDownRoundedIcon style={{ color: "#21DA8C" }} />
            }
            options={domainNames}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            renderOption={(props, option, state) => {
              return (
                <>
                  <li {...props}>
                    <CustomColorCheckbox
                      color="primary"
                      icon={<Checkbox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={state.selected}
                    />
                    <p>{option}</p>
                  </li>
                </>
              );
            }}
            onChange={(event, newValue) => {
              setValue(newValue);
              
              console.log("Selected domain names:", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  fontSize: "14px",
                  fontStyle: "italic",
                  fontVariant: "Nunito Sans",
                }}
                placeholder="Select Domain Names"
                variant="outlined"
                size="small"
              />
            )}
          ></Autocomplete>
        </div>
        <br />
      </div>
    </>
  );
};

export default DomainMultiSelect;
