import { Autocomplete, Checkbox, Icon } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton, makeStyles } from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./AccountIdMultiSelect.css";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import { withStyles } from "@mui/styles";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const CustomColorCheckbox = withStyles({
  root: {
   
    "&$checked": {
      color: "#E842EC"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);


const AccountIdMultiSelect = ({ tenantId, setEnableSync }) => {
  const [selectedAccIds, setSelectedAccIds] = useState([]);
  const [accIdsList, setAccIdsList] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    if (accIdsList.length < 1) {
        refreshAccountIds();
    }
    if (selectedAccIds.length < 1) {
      getSelectedAccIds();
    }
  }, []);

  useEffect(() => {
    if (selectedAccIds.length > 0) {
      setEnableSync(true);
    } else {
      setEnableSync(false);
    }
  }, [selectedAccIds]);

  const getSelectedAccIds = async () => {
    const postData = {
      tenantId: tenantId,
      tool: "LinkedIn",
      keys: ["AccountIds"],
    };
    try {
      let tcreq = await fetch(
        `${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config-json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      let tcjson = await tcreq.json();
      // console.log("tcjson is", tcjson.data.configs[0].configVal);
      // console.log(
      //   "tcjson stripped is",
      //   tcjson.data.configs[0].configVal.map((acc) => Object.values(acc)[0])
      // );
      setSelectedAccIds(tcjson.data.configs[0].configVal);
    } catch (e) {
      console.log("error in getting config", e);
      setSelectedAccIds([]);
    }
  };

  const sendSelectedAccIdsToDb = async (accIds) => {
    const postData = {
      tenantId: tenantId,
      tool: "LinkedIn",
      configArray: [
        {
          tenantConfigKey: "AccountIds",
          tenantConfigValue: accIds,
        },
      ],
    };
    try {
      let tcreq = await fetch(
        `${window._env_.TENANTCONFIG_API}/tenant/set-tenant-config-json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      let tcjson = await tcreq.json();
      // console.log("set tcjson", tcjson);
      setIsDirty(false);
    } catch (e) {
      console.log("error in getting config", e);
      setSelectedAccIds([]);
      setIsDirty(false);
    }
  };

  const refreshAccountIds = async () => {
    try {
      await fetch(
        `${window._env_.LINKEDIN_GOLDENCOPY}/linkedin/getRefreshedAccountIds/${tenantId}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((resJson) => resJson.json())
        .then((result) => {
          setAccIdsList(result.data);
        });
    } catch (e) {
      console.log("error in getAccIdsfromdb, ", e);
      setAccIdsList([]);
    }
  };


 

  return (
    <>
      <div
        style={{ paddingLeft: "4vw", paddingTop: "2vw", borderRadius: "0px" }}
      >
        <Typography style={{ fontWeight: 550, paddingLeft: "3px" }}>
          Sync Campaign Manager Accounts
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Autocomplete
            multiple
            id="accId-auto"
            style={{ width: "576px" , marginBottom:'15px'}}
            popupIcon={
              <KeyboardArrowDownRoundedIcon style={{ color: "#21DA8C" }} />
            }
            options={accIdsList}
            value={[...selectedAccIds]}
            disableCloseOnSelect
            isOptionEqualToValue={(option) => {
              let vals = selectedAccIds.map((acc) => Object.values(acc)[0]);
              if (vals.includes(Object.values(option)[0])) {
                return true;
              }
              return false;
            }}
            getOptionLabel={(option) => {
              console.log("log option label ", option);
              return (
                Object.keys(option)[0] +
                " " +
                option[Object.keys(option)[0]].split(":")[3]
              );
            }}
            renderOption={(props, option, state) => {
              console.log("log renderOptions,", option, state);
              return (
                <>
                  <li {...props}>
                    <CustomColorCheckbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={state.selected}
                    />
                    <p>
                      <b>{Object.keys(option)[0]}</b>{" "}
                      {option[Object.keys(option)[0]].split(":")[3]}
                    </p>
                  </li>
                </>
              );
            }}
            onChange={(event, newValue) => {
              //save it to the tenantConfigs
              console.log("on change,", event, newValue);
              setSelectedAccIds(newValue);
              setIsDirty(true);
              sendSelectedAccIdsToDb(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  fontSize: "14px",
                  fontStyle: "italic",
                  fontVariant: "Nunito Sans",
                }}
                placeholder="Select Ad AccountIds"
                variant="outlined"
                size='small'
              />
            )}
          ></Autocomplete>
        </div>
        <br />
      </div>
    </>
  );
};

export default AccountIdMultiSelect;
