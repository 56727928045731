import React,{useEffect, useState} from "react";
import {useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

function Redirect() {
  // const userDetails = useAuthState();
  // const tenantId = userDetails.user.tenantId;
  // const navigate = useNavigate();
  //   // const [isLoading, setIsLoading] = useState(false)
    
  //   let query = useQuery();
  //   useEffect(() => {
       

  //       let code = query.get("code") || "";

  //       console.log("Redirect api called ", code)
  //       if (code) {
  //         fetch(`${window._env_.GOLDENCOPY_MAILCHIMP}/oauth/mailchimp/callback?code=${code}&tenantId=${tenantId}`, {
  //             method: 'GET',
  //             credentials : 'include',
  //             // headers: {
  //             //     "auth-token" : `${localStorage.getItem('b2bToken')}`
  //             // }
  //         }).then(res => {
  //           if (res.status === 200) {
  //             window.open('http://login.bamboobox.in/#/integration-setup?params=/mailchimp?status=success','_self')
  //             // navigate('/mailchimp?status=success');
  //           } else {
  //             window.open('http://login.bamboobox.in/#/integration-setup?params=/mailchimp?status=failed','_self')
  //             // navigate('/mailchimp?status=failed');
  //           }
            
  //         }).catch(e=>{
  //           window.open('http://login.bamboobox.in/#/integration-setup?params=/mailchimp?status=failed','_self')
  //           // navigate('/mailchimp?status=failed');
  //         })
  //       }
        
  //       // localStorage.setItem('b2_code_sent', 'success');
  //     // api should be called
      
    // });
    return (
      <React.Fragment>
          {/* {isLoading?
          
          :<div style={{textAlign: "center", paddingTop: "45vh"}}><p>MailChimp is authenticated. You can close this tab</p><p>- Team Bamboobox</p></div>} */}
<div style={{ position: "absolute", top: "50%", left: "50%", translate: "transform(-50%,-50%)" }}><CircularProgress color="#1f454f" /></div>
      </React.Fragment>
    );
  }

  export default Redirect;