import React, { useEffect, useState } from "react";
import CustomizedSnackbars from "./snackBar";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core"; //import Card from '@mui/material/Card';
import { Dialog, DialogContent, TextField } from "@material-ui/core"; //import Card from '@mui/material/Card';
import { Opacity } from "@material-ui/icons";
import axios from "axios";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "550px",
    height: "250px",
  },
  dialog: {
    //backgroundColor: 'transparent',
    backgroundColor: "rgba(196, 196, 196, 0.3);",
    //backdropFilter: "blur(3px)",
    opacity: "1",
  },
  textfield: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    marginLeft: "20px",
  },
  dialogButton: {
    marginLeft: "10px",
  },
  input: {
    marginTop: "20px",
  },
  alert:{
    position:'relative',
    width:'100%',
    height:'30px'
  }
}));
const DialogBox = (props) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [openSnack, setOpenSnack] = useState(false);
  const [input, setInput] = useState("");
  const [isError, setIsError] = useState(false);
  const [disable,setDisable]=useState(true)

  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  function handleTest() {
    console.log("hello")
    let reqBodyForKeyTest = {
      "key": input
    }
    // console.log(reqBodyForKeyTest)
     fetch(`${window._env_.INTENT_BACKEND}/intent/test/apiKey`,
      {

        method: 'POST',
        body: JSON.stringify(reqBodyForKeyTest),
        headers: {
          'Content-Type': 'application/json' 
                }
      })
     .then((data)=>data.json())
     .then((checkRes)=>{
      // console.log("after fetch ",checkRes)
    if (checkRes.success === true) {
      console.log("true")
      setOpenSnack(true);
      setIsError(false)
      setDisable(false)
    } else {
      setDisable(true)
      console.log("false")
      setOpenSnack(true);
      setIsError(true)
    }
  })
  }

  function handleConfirm() {
    //api to save the tenant as authorize
    var intentProvider=(props.title).toUpperCase();
    let reqBodyForSave = {
      // "tenantId": tenantId,
      // "tool":props.title.toUpperCase(),
      // "configArray":[{tenantConfigKey:"auth_key",tenantConfigValue:input}]
      "tenantConfigValue":input
    }
    // console.log(reqBodyForSave)
  //  fetch(`${window._env_.TENANTCONFIG_API}/tenant/set-tenant-config/`,
  //${window._env_.CDP_URL}
  fetch(`${window._env_.CDP_URL}/intent/add/account/editKey/${intentProvider}/${tenantId}`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json' 
              },
       body: JSON.stringify(reqBodyForSave),
       credentials: "include",
    })
    .then((data)=>data.json())
    .then((data)=>{
      if(data.success===true)
      {
        props.setIsAuthorize(true);
      }
      else{
        props.setIsAuthorize(false);
      }
    })
  // console.log("checkRes",checkRes)
    props.setOpen(false)
  }
  function handleUpdatedValue(e) {
    setInput(e.target.value);
    // console.log(e.target.value);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogContent className={classes.content}>
        <div className={classes.textfield}>
          <Typography variant="h5">{props.title} Authentication Key</Typography>

          <div>
            <TextField
              className={classes.input}
              variant="outlined"
              placeholder="Label"
              value={input}
              onChange={handleUpdatedValue}
              error={false}
              helperText="The format of the Authentication Key Just in case they have mistaken it for something else"
            />
          </div>
          <div className={classes.alert}>
            {openSnack?<CustomizedSnackbars openSnack={openSnack} setOpenSnack={setOpenSnack} isError={isError} setIsError={setIsError}></CustomizedSnackbars >:<React.Fragment></React.Fragment>}
          </div>
          <div className={classes.button}>
            <Button
              className={classes.dialogButton}
              onClick={handleTest}
              size="small"
              color="primary"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Test
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={handleConfirm}
              size="small"
              color="primary"
              variant="contained"
              disabled={disable}
              style={{
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default DialogBox;
