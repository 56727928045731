import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useEffect, useState } from "react";
import { Box, Tooltip, withStyles } from "@material-ui/core";
import "./createTab.css"
import { useLocation } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IntegrationSwitch from './screens/integrations/integrationSwitch';
import Paper from '@mui/material/Paper';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
      paper: {
        "& ::-webkit-scrollbar-track": {
          backgroundColor:"#FFFFFF!important",
          borderRadius:"80px"
        },
        
        "& ::-webkit-scrollbar": {
          width: "7px",
          height: "5px",
          backgroundColor: "#FFFFFF!important",
        },
        
        "& ::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,0.3)",
          borderRadius: "4px",
        }
      }
    })
)

// const location = useLocation();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let GetTabVal = () => {
  return useQuery().get("tabVal");
}

const CreateTab = ({ tabVal, requestBody, icpSelected, tenantId, segmentName, ...props }) => {

  const CustomTab = withStyles({
    root: {
      textTransform: "none",
      minWidth: "13.8vw",
      width: "13.8vw",
      lineHeight: "15.83px"
    },

  })(Tab);

  // console.log('tab val in createtab is', GetTabVal());
  // console.log('CreateTab page props', props.page);

  const [value, setValue] = useState()

  function handleChange(e, newValue) {
    props.setTabVal(newValue);
  }

  useEffect(() => {

    // console.log("intentNewAccount tabVal CreateTab", tabVal)
    setValue(Number(tabVal) || 0)
  }, [tabVal])

  const classes = useStyles();

  return (

    <div className={classes.paper} >
        {
          props.page==""
          ?
          <Box sx={{ borderBottomWidth: '0.2px', borderBottomColor: "rgb(211, 211, 211)" }}>
          <Tabs
          style={{ borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}
          indicatorColor="primary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#21DA8C",
              height: "6px",
              borderRadius: "3px"
            }
          }}
          value={(Number(tabVal) || 0)}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          variant="standard"
        >
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content" }} label="ALL" ></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content" }} label="CRM"></CustomTab>
          {/* <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content" }} label="DATA ENRICHMENT"></CustomTab> */}
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content" }} label="INTENT DATA"></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content" }}  label="MARKETING AUTOMATIONS"></CustomTab>
          {/* <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content" }} label="SALES ENGAGEMENT" /> */}
          
          </Tabs>
      </Box>
          :
          ""
            }
        
      <br></br>
      {/* <Divider/> */}
      <Box sx={{ width: "100%", }}>
					<Paper  style={{minHeight:"75vh", borderRadius:"8px", backgroundColor:"#FFFFFF",
					boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
					 marginTop:"2vh", marginBottom:"1vh", overflow: "auto"}}>
						<IntegrationSwitch tabVal={tabVal} page={props?.page || ""} />
					</Paper>
      </Box>

    </div>
  )
}
export default CreateTab;