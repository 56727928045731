import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
  <>
  <Stack>
  {props.isError?
  <Alert severity="error" sx={{backgroundColor:'red'}}>Authentication Key was incorrect</Alert> :
  <Alert severity="success" sx={{backgroundColor:'#4CAF50'}}>Authentication Key was Tested Successfully</Alert>}
  </Stack>
   </>   

  );
}