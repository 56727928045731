import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,

} from "react-router-dom";
import App from "./App"
import Redirect from "./redirection/redirect"
import MailChimp from "./screens/mailchimp/mailchimp"
import GoogleAds from "./screens/googleads/Googleads"
import Outreach from "./redirection/outreach"
import LinkedIn from './redirection/linkedIn'
import Rollworks from "./redirection/nextroll";
import HubspotMap from "./redirection/hubspotmap";
import Pardot from "./redirection/pardot"
import UItheme from "@bamboobox/b2-theme"
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import Salesloft from "./redirection/salesloft";
import ZohoCampaigns from "./redirection/zohoCampaigns";
import ZohoMap from "./redirection/ZohoMap";
// import { version } from '../package.json';
// import CacheBuster from 'react-cache-buster';

// console.log("verison of build", version);

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Nunito Sans, sans-serif !important',
    },
  },
});

export default function RouterHandler() {
  return (
    // <CacheBuster
    //     currentVersion={version}
    //     isEnabled={true} //If false, the library is disabled.
    //     isVerboseMode={true}>
    <ThemeProvider theme={theme}>
      <div>
    <Router>
      <Routes >
        <Route path='/' element={<App page="" />} />
        <Route path='/mailchimp' element={<App page="mailchimp" />} />
        <Route path='/googleads' element={<App page="googleads" />} />
        <Route path='/zohocampaigns' element={<App page="zohoCampaign" />} />
        <Route path='/zohocampaigns/callback' element={<ZohoCampaigns />} />
        <Route path='/zohomap' element={<App page="zohoMap" />} />
        <Route path='/zohomap/callback' element={<ZohoMap />} />
        {/* <Route path='/googleads/callback' element={< />} /> */}
        <Route path='/oauth/outreach' element={<Outreach />} />
        <Route path="/salesloft" element={<App page="salesloft"/>}/>
        <Route path="/oauth/salesloft" element={<Salesloft/>}/>
        <Route path='/outreach' element={<App page='outreach' />} />
        <Route path='/oauth/mailchimp/callback' element={<Redirect />} />
        <Route path='/linkedin' element={<App page="linkedin" />} />
        <Route path='/oauth/linkedin/callback' element={<LinkedIn />} />
        <Route path='/nextroll' element={<App page="rollworks" />} />
        <Route path='/oauth/rollworks/callback' element={<Rollworks />} />
        <Route path='/hubspotmap' element={<App page='hubspotmap' />} />
        <Route path='/oauth/hubspot/callback' element={<HubspotMap />} />
        <Route path="/label/:screen" element={<App page='labelScreen' />} />
        <Route path="/pardot" element={<App page="pardot"/>} />
        <Route path='/oauth/pardot/callback' element={<Pardot />} />
        
        {/* <Route path="/oauth/mailchimp/callback">
            <Redirect />
          </Route>
          <Route path="/">
            <App />
          </Route> */}
      </Routes >

    </Router>
    </div>
    </ThemeProvider>
  );
}