import React, { useState,useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomizedSnackbars from './components/snackBar'

import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import IntegrationSwitch from './screens/integrations/integrationSwitch';
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { useStyles } from './constans'

import { Button } from '@mui/material';
import CreateTab from './CreateTab';


function TabPanel(props) {
	const { children, value, index, ...other } = props;
	
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
	
			{value === index && (
				<Box sx={{ padding: "24px 28px", }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);


}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export default function VerticalTabs(props) {
	// console.log("props from line Vertical ", props);
	const [value, setValue] = React.useState(0);
	const [tabVal, setTabVal] = useState(0);
	const classes = useStyles();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box
			sx={{ display: 'flex', borderRadius: 8,marginRight:'' }}
		>
			{/* <Tabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: 'divider', width: '22%', paddingTop: '20px' }}
				textColor="primary"
				indicatorColor="primary"
// export default function VerticalTabs() {
// 	function useQuery(){
// 		return new URLSearchParams(useLocation().search);
// 	}
// 	let GetIcp = () => {
// 		return useQuery().get("outreach") || false;
// 	  }
	 
// 	  const [check,setCheck]=useState(GetIcp())
// 	  console.log(check)
//   const [value, setValue] = React.useState(0);
// const classes = useStyles();
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   const handleOpen=()=>{
// 	setCheck(false)
// }
// console.log(check)
//   return (
//     <Box
// 	sx={{display:'flex'}}
//     >
//       <Tabs
//         orientation="vertical"
//          variant="scrollable"
//         value={value}
//         onChange={handleChange}
//         aria-label="Vertical tabs example"
//         sx={{ borderRight: 1, borderColor: 'divider',width:'22%',paddingTop:'20px'}}
// 		textColor="primary"
//  		indicatorColor="primary"
		  
//       >
//         <Tab label="Integrations" {...a11yProps(0)} 
// 		style={{
// 			textTransform: 'capitalize',
// 			// fontFamily: 'Poppins',
// 			fontStyle: 'normal',
// 			fontWeight: 'bold',
// 			fontSize: '17px',
// 		    alignItems:'flex-start'
// 		}}
// 		/>
//         <Tab label="Set Attribute Values" {...a11yProps(1) }   
// 		style={{
// 			textTransform: 'capitalize',
// 			// fontFamily: 'Poppins',
// 			fontStyle: 'normal',
// 			fontWeight: 'bold',
// 			fontSize: '17px',
// 			alignItems:'flex-start'
// 		}}
// 		/>
//         <Tab label="More" {...a11yProps(2)}  
// 		style={{
// 			textTransform: 'capitalize', 
// 			// fontFamily: 'Poppins',
// 			fontStyle: 'normal',
// 			fontWeight: 'bold',
// 			fontSize: '17px',
// 			alignItems:'flex-start'}}
// 		/>
      
//       </Tabs>
// 	<Box className={classes.container}>
	
//     <TabPanel value={value} index={0} style={{background:'#F9F9F9'}} className={classes.panel}>
// 	{(check=="true"||check==true)&&	<Button onClick={handleOpen}>back</Button>
// }
// <Typography 
// style={{

			>
				<Tab label="Integrations" {...a11yProps(0)}
					style={{
						textTransform: 'capitalize',
						// fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: 'bold',
						fontSize: '17px',
						alignItems: 'flex-start'
					}}
				/>
				{/* <Tab label="Set Attribute Values" {...a11yProps(1)}
					style={{
						textTransform: 'capitalize',
						// fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: 'bold',
						fontSize: '17px',
						alignItems: 'flex-start'
					}}
				/>
				<Tab label="More" {...a11yProps(2)}
					style={{
						textTransform: 'capitalize',
						// fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: 'bold',
						fontSize: '17px',
						alignItems: 'flex-start'
					}}
				/> */}

			{/* </Tabs> */}
			<Box className={classes.container}>

				<TabPanel value={value} index={0} style={{ background: '#F9F9F9' }} className={classes.panel}>

					<Typography
						style={{

							// fontStyle: 'normal',
							fontWeight: '700',
							color: "#595959",
							fontStyle: 'normal',
							fontWeight: '700',
							fontSize: '20px',
							lineHeight: '24px',

							color: '#595959',
							marginBottom:'24px'
						}}


					>Integration Setup</Typography>

					<CreateTab tabVal={tabVal} setTabVal={setTabVal} page={props.page} />


				</TabPanel>


				{/* <TabPanel value={value} index={1} style={{ background: '#E3E3E3' }}>
					Attribute Values
				</TabPanel>
				<TabPanel value={value} index={2} style={{ background: '#E3E3E3' }}>
					More
				</TabPanel> */}
			</Box>

		</Box>
	);
}
