import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "@bamboobox/b2logincheck";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { toast, ToastContainer } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Check } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: "32px"
  },
  textField: {
    marginLeft: '1.3rem',
    width: 200,
    height: "10vh",
    borderRadius: "4px"
  },

  instructionFrame: {
    position: 'absolute',
    width: '20vw',
    height:"60vh",
    marginTop:"50px",
    right: '5%',
    top: '14%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  headingFrame: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    position: 'relative',
    left: '5%',
    top: '5%',

  },
  InfoOutlined: {
    color: "#21DA8C!important",
    "& .css-1ps6pg7-MuiPaper-root": {
      color: "#21DA8C!important"
    }
  },
  heading: {
    position: 'relative',
    left: '2%',
    top: '5%',
    fontFamily:'Nunito Sans !important',
    fontFtyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '175%',
    /* identical to box height, or 28px */

    letterspacing: '0.15px',

    /* text/primary */

    color: 'rgba(0, 0, 0, 0.87)',


    /* Inside auto layout */

    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: '0px 8px',
  },

  instructionText: {
    position: 'relative',
    fontFamily:'Nunito Sans !important',

    left: '5%',
    top: '7%',
    paddingRight: '10%',

    /* body1 */

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    /* or 21px */

    letterSpacing: '0.15px',

    /* text/primary */

    color: 'rgba(0, 0, 0, 0.87)',
  }
}));


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Rollworks() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
  // const [value, setValue] = React.useState(null);
  let query = useQuery();
  const classes = useStyles();
  let [status, setStatus] = useState(query.get("status") || "");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [externalPopup, setExternalPopup] = useState(null);
  const [enableSync, setEnableSync] = useState(false); 

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        let currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        currentUrl = currentUrl.replace('/#', '');
        currentUrl = new URL(currentUrl);
        const login_success = new URLSearchParams(currentUrl.search).get("status");
        if (login_success) { 
          externalPopup.close();
          setExternalPopup(null);
          setStatus(login_success);
          timer && clearInterval(timer);
        }
      }
      catch (e) {
        console.log("external Popup", e);
        return;
      }
    }, 100)
  },
    [externalPopup]
  );


  function handleAuth() {
    const width = 550;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `WINDOW TITLE`;
    const url = `https://services.adroll.com/auth/authorize?${new URLSearchParams({
      response_type: "code",
      client_id: window._env_.ROLLWORKS_CLIENT_ID,
      redirect_uri: window._env_.ROLLWORKS_OAUTH_CALLBACK
    })}`;
    const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
  }

  const BulkSyncHandler = async (event) => {
    if (!startDate) {
      toast("Please provide a Start Date");
      return;
    }
    if (!endDate) {
      toast("Please provide a End Date");
      return;
    }

    const body = {
      startDate : startDate,
      endDate: endDate,
      tenantId: tenantId
    }

    fetch(`${window._env_.ROLLWORKS_CLIENT_ID}/rollworks/activities`, {
      method: 'GET',
      credentials: 'include',
      body: body
    });
    toast(`Nextroll sync started.`);
  }
  
  return (
    <React.Fragment>

      <span style={{ display: "flex", padding: "0px 0px 0px 0px", marginTop: "19px", marginLeft: "32px" }}>
        <ArrowBackIosNewIcon sx={{ color: "#37CAD3", cursor: "pointer" }} onClick={() => { navigate('/') }} />
        <Typography variant="Ag" gutterBottom sx={{ fontStyle: "nunito sans", fontSize: "18px", fontWeight: "600", marginLeft:"8px"}} ><b>Nextroll</b></Typography>
      </span>

      {/* {!(status === "success" || status === "authenticated" ) ? <></> : 
        <AccountIdMultiSelect tenantId={tenantId} setEnableSync={setEnableSync}/>
      } */}

      <Stack spacing={1} direction="row" style={{ paddingLeft: "4vw", display: "block", marginTop: "0px" }}>
        <form className={classes.container} noValidate style={{marginLeft:'0px'}}>
          {!(status === "success" || status === "authenticated" ) ? <></> :
          <>
            <TextField
              id="date"
              type="date"
              style={{marginLeft:'0px'}}
              label="Sync from *Date*"
              value={startDate}
              disabled={!enableSync}
              variant="outlined"
              className={classes.textField}
              onChange={(event) => {
                console.log("coming ffrom event", event);
                setStartDate(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              type="date"
              style={{marginLeft:'0px'}}
              label="Sync to *Date*"
              value={endDate}
              disabled={!enableSync}
              variant="outlined"
              className={classes.textField}
              onChange={(event) => {
                console.log("coming ffrom event", event);
                setEndDate(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            </> }
        </form>
        <div style={{ marginLeft: "450px" }}>

          {!(status == "success" || status == "authenticated") ?
            <Button style={{ background: "#21DA8C", width: "113px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "Nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px",marginTop: '60px' }} variant="contained" disabled={status !== "failed" && status} onClick={handleAuth} startIcon={<Check />}>
              INTEGRATE
            </Button> :

            <Button onClick={BulkSyncHandler} style={{ background: "#21DA8C", width: "113px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "Nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} variant="outlined" disabled={!((status === "success" || status === "authenticated") && enableSync)}>Sync</Button>

          }
        </div>
        {/* <Button onClick={handleAuth} style={{ marginLeft: "1.3rem" }} variant="contained" disabled={status !== "failed" && status}>Authenticate</Button> */}

      </Stack>

      <Stack spacing={3} direction="row" style={{ margin: "0 auto", display: "block", marginTop: "5vh" }}>


        <ToastContainer />
      </Stack>

      <div className={classes.instructionFrame}>
        <div className={classes.headingFrame}>
          <InfoOutlinedIcon color="primary" />
          <p className={classes.heading}>
            Instruction
          </p>
        </div>
        <p className={classes.instructionText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed turpis velit. Praesent vel varius elit, a viverra ex. Mauris malesuada commodo felis nec vestibulum. Cras pulvinar, purus a maximus gravida, ipsum lectus aliquam ante, et laoreet nisi lorem vitae augue. Proin at justo quis leo malesuada dapibus. Nullam pellentesque magna ut consequat viverra. Sed semper velit et tempor sodales.
        </p>
      </div>

    </React.Fragment>
  );
}

export default Rollworks;