import React, { useState } from 'react';
import {Button,Typography} from '@material-ui/core' //import Card from '@mui/material/Card';
import {Dialog,DialogContent,TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import { Padding } from '@mui/icons-material';

const useStyles = makeStyles(theme=>({
   dialog:{
       width:"550px",
       height:"206px"
     
   } ,
   heading:{
      display:'flex',
      flexDirection:'column',
   }  ,
   button:{
       display:'flex',
       justifyContent:'flex-end',
       paddingTop:'50px'

   }


}))

const UpdateDialog = (props) => {
    const classes = useStyles()
    var open = props.open
    var setOpen = props.setOpen
    function clickHandle()
    {
        props.setUpdateOpen(false)
        setOpen(true)
    }
    function clickHandleNo()
    {
        props.setUpdateOpen(false)
    }
    const [openDialog,setOpenDialog] = useState(false);
    return (
        <Dialog
        
        open={props.updateOpen}
        onClose={()=>props.setUpdateOpen(false)}
        >
        <DialogContent 
        className={classes.dialog}
        >
        <div 
        className={classes.heading}>
        <Typography variant="h6">Do you want to change the intent provider</Typography>
        <Typography variant="subtitle1">The previous intent provider will be removed and scores  associated with it will be archived</Typography>
        {/* <h6>intent provider</h6> */}
         <div className={classes.button}>
        <Button  size="small" color="primary" variant="outlined" onClick={clickHandleNo} style={{textTransform: 'capitalize',fontWeight:'500',fontSize:'14px',marginLeft:'10px'}} >No</Button>
        <Button  size="small" color="primary" variant="contained" onClick={clickHandle} style={{textTransform: 'capitalize',fontWeight:'500',fontSize:'14px',marginLeft:'10px'}}>Yes</Button>
        </div>
        </div>
         </DialogContent>
        </Dialog>
    );
};

export default UpdateDialog;