import React,{useEffect, useState} from "react";
import {useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Pardot() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState(false)
    
    let query = useQuery();
    useEffect(() => {
       

        let code = query.get("code") || "";


        // console.log("Pardot api called ", `${window._env_.PARDOT_MAP_GOLDENCOPY}/pardot/auth/callback/?code=${code}&tenantId=${tenantId}`);
        if (code) {

          fetch(`${window._env_.PARDOT_MAP_GOLDENCOPY}/pardot/auth/callback/?code=${code}&tenantId=${tenantId}`, {

              method: 'GET',
              credentials : 'include',
            //   headers: {
            //       "auth-token" : `${localStorage.getItem('b2bToken')}`
            //   }
          }).then(res => {
            if (res.status === 200) {
              navigate('/pardot/?status=success');
            } else {
              navigate('/pardot?status=failed');
            }
            
          }).catch(e=>{
            navigate('/pardot?status=failed');
          })
        }
        
        // localStorage.setItem('b2_code_sent', 'success');
      // api should be called
      
    });
    return (
      <React.Fragment>
          {/* {isLoading?
          
          :<div style={{textAlign: "center", paddingTop: "45vh"}}><p>LinkedIn is authenticated. You can close this tab</p><p>- Team Bamboobox</p></div>} */}
<div style={{ position: "absolute", top: "50%", left: "50%", translate: "transform(-50%,-50%)" }}><CircularProgress color="#1f454f" /></div>
      </React.Fragment>
    );
  }

  export default Pardot;