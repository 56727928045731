import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Integration from './integration';
import Mailchimp from './../mailchimp/mailchimp';
import GoogleAds from "../googleads/Googleads";
import ZohoCampaign from "../zohoCampaigns/zohoCampaign";
import ZohoMap from "../zohoMap/zohoMap";
import { useAuthState } from "@bamboobox/b2logincheck";
import OutreachSync from "../../screens/outreach";
import LinkedIn from '../linkedIn/linkedIn';
import Rollworks from '../nextroll/nextroll';
import HubspotMap from "../hubspotmap/hubspotmap";
import Pardot from "../pardot/pardot";
import LabelScreen from "../labelScreen";
import SalesloftSync from "../SalesloftSync";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function IntegrationSwitch(props) {

  console.log("props from 14", props);
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false)

  let query = useQuery();


  // useEffect(() => {




  //     // localStorage.setItem('b2_code_sent', 'success');
  //   // api should be called

  // });
  return (
    <React.Fragment>
      {
        {
          '': <Integration tabVal={props.tabVal} />,
          'mailchimp': <Mailchimp />,
          'outreach': <OutreachSync />,
          'googleads': <GoogleAds />,
          'zohoCampaign': <ZohoCampaign />,
          'zohoMap': <ZohoMap />,
          'linkedin': <LinkedIn />,
          'rollworks': <Rollworks />,
          'hubspotmap': <HubspotMap />,
          'labelScreen': <LabelScreen />,
          'salesloft':<SalesloftSync />,
          'pardot': <Pardot/>
        }[props?.page || ""]
      }
    </React.Fragment>
  );
}

export default IntegrationSwitch;